













































.allSolutionFound {
  max-height: 50vh;
  overflow: auto;
}
