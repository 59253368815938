



























































































































































































































































$color: rgb(126, 126, 126);
$animation-speed: .15s;

.inter {
  font-size: 14px;
  max-width: 100%;
  .visuel-container {
    position: relative;
    display: block;
    margin-bottom: 15px;
    transition: margin-bottom $animation-speed linear, height $animation-speed linear;
    .wrapper {
      display: inline-block;
      transition: transform $animation-speed linear, width $animation-speed linear;
    }
    .interactive-layer {
      .area {
        position: absolute;
        z-index: 2;
      }
      .area-dots {
        $space: 2px;
        top: $space;
        left: $space;
        border: 2px dashed white; // var(--primary-color);
        width: calc(100% - #{$space * 2});
        height: calc(100% - #{$space * 2});
        position: absolute;
        display: flex;
        align-items: center;
      }
      .area-inner {
        $space: 0px;
        position: absolute;
        top: -$space;
        left: -$space;
        background-color: rgba(var(--primary-color-values), .4);
        width: calc(100% + #{$space * 2});
        height: calc(100% + #{$space * 2});
      }
      span {
        font-size: 2em;
        color: white;
        margin: 0 auto;
      }
    }
    .free-areas {
      opacity: 0;
    }
    .visuel {
      z-index: 1;
      transition: all 500ms ease;
      filter:
        drop-shadow(2px 3px 2px rgba($color, .30))
        drop-shadow(6px 7px 6px rgba($color, .20))
        drop-shadow(10px 11px 12px rgba($color, .10))
        drop-shadow(16px 18px 16px rgba($color, .06))
        drop-shadow(20px 24px 20px rgba($color, .02));

      margin-left: auto;
      margin-right: auto;

      .interVisuel {
        background-size: contain;
      }
    }
  }
  .setDarkMode {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 25px;
    position: relative;
    & > div {
      height: 18px;
      display: inline-block;
      cursor: pointer;
    }
    .moon,
    .sun {
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .toggle {
      width: 30px;
      border-style: solid;
      border-width: 1px;
      border-radius: 9px;
      box-sizing: border-box;
      margin: 0 10px;
      height: 16px;
      .state {
        transition: margin-left .25s linear;
        height: 10px;
        width: 10px;
        margin: 2px;
        border-radius: 6px;
      }
    }
    &.off {
      .state {
        background-color: black;
      }
      .toggle {
        border-color: black;
      }
      .moon {
        background-image: url('/static/mobile/imgs/moon-black.png');
      }
      .sun {
        background-image: url('/static/mobile/imgs/sun-black.png');
      }
    }
    &.on {
      .state {
        margin-left: calc(100% - 12px);
        background-color: white;
      }
      .toggle {
        border-color: white;
      }
      .moon {
        background-image: url('/static/mobile/imgs/moon-white.png');
      }
      .sun {
        background-image: url('/static/mobile/imgs/sun-white.png');
      }
    }
  }
  .titre {
    font-size:15px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .tarif {
    font-size:15px;
    font-weight: 600;
  }
}
.interVisuelShadow {
  display: none;
}
.darkModeEnabled {
  .titre, .tarif, .addToCart, .interrupteur-details {
    color: white;
    position: relative;
  }
}
.camera-process-video,
.camera-process-photo,
.camera-enabled{
  .titre, .tarif{
    display: none;
  }
  .wrapper .visuel{
    transform: scale(1);
    opacity: 1;
  }
  .inter .setDarkMode{
    opacity: 0;
  }
}
.camera-animation{
  .wrapper .visuel{
    transform: scale(.4);
    opacity: 0;
  }
}
