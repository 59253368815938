















































$primaryColor: var(--primary-color);
.loader {
  margin-top: 40vh;
  max-width: 280px;
  .error {
    margin-top: 1em;
    color: $primaryColor;
  }
  .progress-container {
    width: 100px;
    position: relative;
    border: 2px solid rgba(0,0,0,.2);
    border-radius: 10px;
    padding: 2px;
  }
  .progress {
    border-radius: 5px;
    height: 10px;
    background-color: $primaryColor;
  }
}
