












































.banner {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    transition: opacity 200ms;
    cursor: pointer;

    &.hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &:hover {
    img.hover {
      opacity: 1;
    }
  }
}
