


























.tarif {
  color: var(--primary-color)
}
