









































































$color: rgb(0, 0, 0);
$btnSize: 30px;
.last-selector .list-formats {
  ul {
    align-items: center;
    li{
      cursor: pointer;
      .preview-container {
        padding: 10px;
      }
    }
  }
  li:hover,
  li.active {
    background-color: rgba(var(--primary-color-values), 0.1);
    .plaque-bg {
      fill: white
    }
  }

  svg {
    width: 100%;
    filter:
        drop-shadow(0px 0px 1px rgba($color, .15))
        drop-shadow(2px 2px 2px rgba($color, .10))
        drop-shadow(3px 3px 3px rgba($color, .05));
        // drop-shadow(4px 4px 4px rgba($color, .03))
        // drop-shadow(5px 5px 5px rgba($color, .01))
  }
}
