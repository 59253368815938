

















































.cart-indicator {
  margin-right: 25px;
  .cart-button {
    padding-right: 10px;

    .cart {
      position: relative;
      min-width: 50px;
    }
  }
}
a {
  font-size: 22px;
  .price {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary-color);
  }
}
.indicator {
  pointer-events: none;
  $size: 16px;
  $fontSize: 8px;
  color: white;
  font-size: $fontSize;
  padding: ($size - $fontSize - 2) / 2;
  line-height: normal;
  height: $size;
  min-width: $size;
  vertical-align: text-top;
  border-radius: $size / 2 ;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: calc(50% - 0px);
  right: calc(50% - 15px);
  font-family: Roboto,Arial,Verdana,Helvetica,sans-serif;
}
