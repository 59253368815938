




















$btnSize: 40px;
.camera-button-close{
  display: flex;
  justify-content: center;
  align-items: center;
  width: $btnSize;
  height: $btnSize;
  position: absolute;
  top: -70px;
  right: 10px;
  z-index: 12;
  overflow: hidden;

  &:before {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $btnSize - 10px;
    height: $btnSize - 10px;
    font-size: 30px;
    box-sizing: border-box;
    content: "\2715";
    color: white;
  }
}
