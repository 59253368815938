














































































































































.interrupteur-drag {
  z-index: 1;
  position: fixed;
}
