














































































$borderColor: rgba(255, 255, 255, .7);
.gamme-item {
  width: 100%;
  display: block;
  border-top: 1px solid $borderColor;
  cursor: pointer;
  & > div, & > img {
    display: inline-block;
    vertical-align: middle;
    transition: all 250ms ease;
  }
  &:hover{
    & > div, & > img{
      transform: translateX(10px);
    }
  }
  .thumb {
    width: 67px;
  }
  .arrow {
    width: 20px;
  }
  .text {
    width: calc(100% - 67px - 20px)
  }
  & + .gamme-item {
    border-bottom: 1px solid $borderColor;
  }
  &.item-disabled{
    opacity: .5;
    p {
      color: #333;
    }
  }
}
