











































































.morph.loaded {
  transition: top .5s ease-out,
              left .5s ease-out,
              opacity .3s ease-out,
              width .5s cubic-bezier(0,.98,1,.99),
              height .5s cubic-bezier(0,.98,1,.99),
              background .5s ease-out,
              border-radius .5s ease-out;
}
.morph {
  opacity: 0;
  display : block;
  position: fixed;
  background-color: red;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1;
}
