














































































.interrupteur-fingers {
  display: inline-block;
  position: relative;
  margin-top: 0px;
  .activeSymbol {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    transition: left linear .25s;
    padding: 14px 16px 9px;
  }
  .sample {
    background-color: silver;
  }
  .finger {
    &:not(.active) {
      cursor: pointer;
    }
    padding: 20px 14px 9px 14px;
    .border {
      padding: 0px;
      transition: border-color linear .25s .25s;
    }
  }
  .finger, .activeSymbol {
    display: inline-block;
  }
  .finger .border {
    border: 1px solid rgba( 0, 0, 0, .2);
  }
  .finger .border, .finger .sample, .activeSymbol > div {
    display: block
  }
  .finger .sample, .activeSymbol > div {
    background-size: contain;
    width: 1em;
    height: 1em;
  }
  .finger.active .border {
    border-color: rgba( 0, 0, 0, .6);
  }
  .activeSymbol.active {
    border-top: 4px solid var(--primary-color);
  }
}
.darkModeEnabled {
  .finger.active .border {
    border-color: rgba( 255, 255, 255, .6);
  }
  .interrupteur-fingers {
    .activeSymbol.active {
      z-index: 0;
      border-top-color: #FFFFFF;
    }
  }
}
.is-lateral-configuration-menu {
  .interrupteur-fingers {
    .finger {
          padding: 20px 14px 3px 14px;
    }
  }
}

