






























.camera-button-activation{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -40px);
  transition: all 250ms ease;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  i{
    font-size: 25px;
    margin-right: 4px;
  }
  span{
    text-decoration: underline;
    font-size: 12px;
    font-weight: 700;
    padding-top: 5px;
  }
}
