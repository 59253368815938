























































































































































































































































































































































































































































































































































$btnSize: 20px;
$btnAddSize: 75px;
$breakSmallHeight: 568px;
$navHeight: 64px;

.in-out-slide-fade-enter-active, .in-out-slide-fade-leave-active,
.modal-in-out-slide-fade-enter-active, .modal-in-out-slide-fade-leave-active,
.in-out-fade-enter-active, .in-out-fade-leave-active{
  transition: all .4s;
}
.in-out-slide-fade-enter, .in-out-slide-fade-leave-active,
.modal-in-out-slide-fade-enter, .modal-in-out-slide-fade-leave-active,
.in-out-fade-enter, .in-out-fade-leave-active {
  opacity: 0;
}
.in-out-slide-fade-enter {
  transform: translateY(31px);
}
.in-out-slide-fade-leave-active {
  transform: translateY(31px);
}

.mobile-selectors {
  & > * {
    pointer-events: all;
  }
}

.modal-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  padding: calc(#{$navHeight} + 30px) 20px 30px;

  .close {
    display: flex;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 10px;
    top: calc(#{$navHeight} + 8px);
    z-index: 100;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    i{
      font-size: 13px;
      padding-top: 2px;
    }
    &:before{
      content: '';
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  h2 {
    text-align: left;
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .selectedCategory {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border:1px solid rgba(226, 228, 230, 1);
  }
}

.pn-product-nav-wrapper {
  position: relative;
  box-sizing: border-box;
  .pn-product-nav {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .pn-product-nav-contents {
      min-width: 100%;
      float: left;
      transition: transform .2s ease-in-out;
      position: relative;
    }
    .pn-product-nav-contents-no-transition {
      transition: none;
    }
  }
}
.last-selector {
  ul {
    display: flex;
    justify-content: center;
    overflow: auto;
    flex-wrap: wrap;
    li.active {
      background-color: rgba(0, 0, 0, .07);
    }
  }
}
.first-selector, .second-selector {
  color: var(--primary-color);
  ul {
    display: flex;
    justify-content: space-between;
    gap: 10px 10px;
    li {
      cursor: pointer;
      vertical-align: middle;
      border-style: solid;
      border-width: 1px;
      text-transform: capitalize;
    }
  }
}

.first-selector {
  &.active:before {
    opacity: 0;
  }
  & > ul {
    margin: 0 10px;

    & > li {
      transition: all 250ms ease;
      border-color: var(--primary-color);
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      width: 100%;
      text-transform: uppercase;
      background-color: #FFFFFF;
      &:hover{
        background-color: rgba(var(--primary-color-values),.1);
      }
      &.active {
        background-color: rgba(var(--primary-color-values), 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.second-selector {
  margin-bottom: 20px;
  ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      border-color: rgba(226, 228, 230, 1);
      color: rgba(0, 0, 0, .7);
      word-break: unset;
      white-space: nowrap;
      word-break: unset;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
      transition: all 250ms ease;
      padding: 0 5px;
      &:hover,
      &.active{
        background-color: rgba(226, 228, 230, 1);
      }
    }
  }
}

.last-selector{
  overflow: auto;
  ul{
    display: grid;
    gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: auto;
    li{
      width: 100%;
      display: flex;
      border: 1px solid rgba(226, 228, 230, 1);
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.list-fonctions li.item, .list-plaques li.item, .list-formats li.item {
  padding-bottom: 0px;
  .text span {
    max-height: 33px;
    white-space: initial;
    color: rgba(0, 0, 0, 0.7);
  }
}
.list-formats li.item {
  .text span {
    width: 78px;
  }
}

.list-plaques{
  .thumb{
    transform: scale(1.2);
    transform-origin: top;
  }
}

.camera-enabled {
  .modal-container {
    padding-top: 20px;
    z-index: 12;

    .close {
      top: 0;
    }
  }
}

