

















































































































li.item {
  vertical-align: top;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
  &:hover{
    span{
      text-decoration: underline;
    }
  }
  // width:65px;
  .visuel {
    display: inline-block;
  }
  .text {
    span {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      text-transform: capitalize;
      font-size: 11px;
      line-height: 11px;
      font-weight: 600 !important;
      word-break: normal;
      hyphens: auto;
    }
  }
}
