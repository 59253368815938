































































$btnSize: 30px;

.list-item {
  z-index: 1;

  & > ul {
    background: white;
    width: 100%;
    margin: 0 auto;
    & > li {
      touch-action: auto;
    }
  }
}
