





























































































































































.deleteFonction {
    position: absolute;
    z-index: 11;
    font-size: 10px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    text-align: center;
    line-height: 0;
    padding: 7px
}
