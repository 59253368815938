


























































.functionsNeedStarterPack {
  p {
    margin-top: 7px;
  }
}
.actions {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
