




































.gamme-list {
  padding-left: 30px;
  padding-right: 30px;
  & > ul {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}
