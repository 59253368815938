





























































































.interrupteur-details {
  & > a {
    color: #333;
    text-decoration: underline;
    font-size: 0.8em;
    padding: 20px 20px 10px 20px;
    display: block;
  }
  .ref {
    display: block;
    margin: 1em auto;
    max-width: 60%;
    min-width: 250px;
    font-size: .8em;
    line-height: 1.4em;
    & > * {
      display: inline;
      margin: 0 .2em;
    }
    .tarif {
      font-weight: 500;
    }
  }
}
.darkModeEnabled {
  .interrupteur-details {
    & > a {
      color: white;
    }
  }
}
