












































































































































































































































































































































































































































































































































































$animation-speed: .15s;
.fingers-container {
  display: flex;
  transition: all 250ms ease;
  & > :not(.center) {
    flex-grow: 1;
    flex-basis: 40px;
    p {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: right;
    }
  }
}
@media screen and (max-width: 375px) {
  .fingers-container {
    & > :not(.center) {
      visibility: hidden;
    }
  }
}
.finger-title * {
  font-weight: 500;
}
.configuration .addToCart{
  &:active {
    background: var(--primary-variant-color);
    background: -moz-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
    background: -webkit-linear-gradient(top, var(--primary-variant-color) 0%, var(--primary-color) 100%);
    background: linear-gradient(to bottom, var(--primary-variant-color) 0%, var(--primary-color) 100%);
  }
}
.configuration.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .vertical-center {
    transition: padding-top $animation-speed linear;
    flex-grow: 1;
    position: relative;
  }
}
.visuel-container {
  text-align: center;
  .visuel {
    position:relative;
    display: inline-block;
  }
}
.temporaryAddToCart {
  width: 60px;
  height: 60px;
  position: absolute;
  left: calc(100vw - 50px + 1px);
  top: calc(((50px / 2) - 2px) * -1);
}
.is-lateral-configuration-menu {
  .fingers-container,
  .configuration.view .vertical-center{
    padding-left: 350px;
  }
}
@media only screen and (min-width: 1920px){
  .is-lateral-configuration-menu {
    .fingers-container,
    .configuration.view .vertical-center{
      padding-left: 0;
    }
  }
}
.darkModeEnabled {
  background-color: #262627;
  .fingers-container {
    color: white;
  }
}
.camera-process-video,
.camera-process-photo,
.camera-enabled{
  .addToCart-wrapper, .interrupteur-details{
    display: none;
  }
  .fingers-container{
    transform: translateY(-200%);
  }
}
