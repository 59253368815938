



































































































































.camera-video-wrapper{
  .camera-zoom{
    background: none;
    max-width: 100px;
    border: 0;
    padding: 0;
    &:disabled{
      opacity: 0.3;
    }
  }
  .camera-video{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    opacity: 0;
    transition: all 500ms ease;
  }
  .camera-video-information{
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 1000ms ease 0s;
    transform: translateY(-20px);
    flex-direction: column;
    p{
      color: #fff;
      width: 50%;
      font-size: 26px;
      line-height: 120%;
      font-weight: 700;
    }
    i{
      font-size: 114px;
      color: #fff;
      margin-top: 30px;
    }
  }
}
.configuration.camera-process-video{
  &.camera-enabled:not(.camera-animation){
    .camera-video-wrapper{
      .camera-controls{
        margin-bottom: 0;
        transition-delay: 500ms;
      }
    }
  }
  &.camera-animation{
    .camera-video-wrapper{
      .camera-video-information{
        transform: translateY(0px);
        opacity: 1;
        transition-delay: 1000ms;
      }
    }
  }
}
