



























































































































































































































































































































































































































.list-complete-item {
  transition: all 0.8s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
  width: 100%;
  z-index: -1;
  // margin-left: 100%;
}
.list-complete-leave-active {
  position: absolute;
}
.turnstile-badge {
  visibility: hidden;
}
.cart.view {
  min-height: 100%;
  position: relative;

  .cart-detail {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .cart-empty {
    h2 {
      margin-top: 100px;
    }
  }
  h2 {
    font-size: 1.1em;
    font-weight: 500;
  }
  .add-product {
    padding-top: 10px;
    padding-bottom: 60px;
    a:hover{
      text-decoration: underline;
    }
  }
  .actions {
    position: fixed;
    background-color: #FFFFFF;
    bottom: 0;
    left:0;
    width: 100%;
    z-index: 1000;

    #turnstile-holder{
      flex-direction: column;

      & > div {
        font-size: 13px;
        font-weight: 500;
        padding: 3px 0;
        box-shadow:0px -4px 4px 0px #FFFFFF;
      }
    }
    .row-action {
      display: flex;
      width: 100%;

      #inv-turnstile-holder {
        display: table-row;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;

        & > div {
          margin-left: auto;
          margin-right: auto;
        }
      }

      a {
        height: 45px;
        padding: 5px 18px;
        font-weight: 500;
        font-size: 13px;
        flex: 1 1 auto;
        -ms-flex-item-align: center;
        align-self: center;
        display: flex;
        cursor: pointer;
        &.download-pdf {
          // margin-right: 2px;
        }
        &.external-links {
          margin-left:1.5%;
          border: 1px solid var(--primary-color);
          background-color: #FFFFFF;
        }

        span {
          display: -ms-flexbox;
          -ms-flex-item-align: center;
          margin: auto;

          .icon {
            font-size: 26px;
            margin-right: 13px;
          }
        }
      }
    }

    &.mobile-actions {
      flex-wrap: wrap;
    }

    &.desktop-actions {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      max-width: 255px;
      margin-top: 56px;

      .desktop-actions-container {
        position: sticky;
        top: 5px;

        #inv-turnstile-holder {
          & > div {
            max-width: 255px;
          }
        }

        .row-action {
          flex-direction: column;

          a {
            width: 100%;

            & + a {
              margin-top: 15px;
            }

            &.form-elec-action {
              position: relative;
              display: flex;
              flex-direction: column;
              height: auto;
              justify-content: center;
              align-items: center;
              padding: 13px;

              img {
                position: absolute;
                right:26px;
                top: 13px;
                width: 50px;
              }

              .icon {
                font-size: 62px;
                margin-top: 25px;
              }

              div {
                margin-top: 13px;
              }

              .button {
                margin-top: 13px;
                background-color: #FFFFFF;

                &:hover {
                  background-color: #FFFFFF;
                  background: #FFFFFF;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .turnstile-disclaimer {
    position: absolute;
    bottom: 15px;
    width: 100%;
    font-size: 10px;

    a {
      text-decoration: underline;
    }
  }
}
