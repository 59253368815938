









































































































































































































.poste, .plaque {
  box-sizing: content-box;
}
.poste {
  position: absolute;
  border: 1px solid black;
}
.plaque {
  border: 2px solid black;
  position: relative
}
