















































.camera-photo-wrapper{
  .camera-canvas{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    opacity: 0;
    transition: all 500ms ease;
  }
}
.camera-process-photo{
  &:not(.show-interface){
    &.camera-enabled{
      .camera-photo-wrapper{
        .camera-controls{
          margin-bottom: 0;
          transition-delay: 500ms;
        }
      }
    }
  }
  &.show-interface{
    .camera-canvas{
      filter: blur(10px);
    }
  }
  .camera-canvas{
    opacity: 1;
  }
}
